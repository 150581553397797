var site = site || {};

site.client = site.client || {};
site.direction = site.direction || {};

(function ($) {
  Drupal.behaviors.productGridV3 = {
    attach: function (context) {
      var isMobile = Unison.fetch.now().name === 'small';
      var $mppNode = $('.node-elc-mpp', context);
      var $mppContainer = $('.js-mpp-container', context);
      var $controlsContainer = $('.js-mpp-container__controls', $mppContainer);
      var $filterActiveOptionsContainer = $(
        '.js-mpp-filter-active-options__container',
        $controlsContainer
      );
      var $filtersWrapper = $('.js-mpp-filter-set-v2', $controlsContainer);
      var $filtersContainer = $('.js-mpp-filter-set__container', $filtersWrapper);
      var $gridCount = $(
        '.js-breadcrumb__level--count-number, .js-mpp-product-count-number',
        $mppNode
      );
      var $grids = $('.js-product-grid-v3', context);
      var $carouselGrids = $grids.filter('.js-product-grid--carousel');
      var $carousels = $('.js-product-carousel', $carouselGrids);
      var $products = $('.js-product-grid-item-product', $mppContainer);
      var $productCountProductSuffix = $('.js-mpp-product-count-product-suffix', $mppContainer);
      var $productCountProductsSuffix = $('.js-mpp-product-count-products-suffix', $mppContainer);
      var preFilterString = $mppContainer.data('preFilterString') ?? 'all';
      var pageloadAnimation = preFilterString !== 'all';
      var loadingAnimation = !isMobile && pageloadAnimation;

      if (!$mppContainer.hasClass('js-product-grid-filterable')) {
        if ($products.length > 1) {
          $productCountProductSuffix.addClass('hidden');
          $productCountProductsSuffix.removeClass('hidden');
        } else {
          $productCountProductSuffix.removeClass('hidden');
          $productCountProductsSuffix.addClass('hidden');
        }
        $gridCount.text($products.length).parent().removeClass('hidden');
      }
      initControlls();
      $products.each(function () {
        var $product = $(this).hasClass('js-product') ? $(this) : $(this).find('.js-product');

        if ($product.length > 1) {
          var skuBaseId = $product.attr('data-sku-base-id');

          $product.trigger('product.updateRoute', [skuBaseId]);
        }
      });
      initCarouselsGrid();
      setEvents();
      // mixItUp Settings
      // https://github.com/patrickkunka/mixitup/blob/v2/docs/configuration-object.md
      var itemSelector = '.js-product-grid-item';
      var $mixItUpEl = $('.js-product-grid__wrapper', context);
      var bypassOrder = true;
      var defaultSort = bypassOrder ? 'default' : 'inv-sort:asc default';
      var mixItUpArgs = {
        selectors: {
          target: itemSelector,
          sort: '.js-mpp-sort-option'
        },
        layout: {
          display: 'flex'
        },
        load: {
          filter: preFilterString,
          sort: defaultSort
        },
        animation: {
          enable: loadingAnimation
        },
        callbacks: {
          onMixLoad: function () {
            $(this).mixItUp('setOptions', {
              animation: {
                enable: true
              }
            });
          }
        }
      };

      $mixItUpEl
        .on('mixStart', function () {
          // On start.
        })
        .on('mixEnd', function () {
          // Display # of filtered results
          var $item = $(
            '.js-product-grid-item-product' + ':visible:not(".js-product-grid-item-promo")',
            $mixItUpEl
          );

          $gridCount.text($item.length);

          if ($item.length > 1) {
            $productCountProductSuffix.addClass('hidden');
            $productCountProductsSuffix.removeClass('hidden');
          } else {
            $productCountProductSuffix.removeClass('hidden');
            $productCountProductsSuffix.addClass('hidden');
          }

          // Animate grid
          $mixItUpEl.fadeTo(200, 1, function() {
            $mixItUpEl.parent().addClass('mixitup-init-product-grid');
          });

          // grid.reflow is used for rebuilding the filters, it's required to trigger on each mixEnd
          $(document).trigger('grid.reflow');
        })
        .on('mixFail', function () {
          // $failContainer.show();
        })
        .addClass('mixitup-initialized')
        .mixItUp(mixItUpArgs);

      function setEvents() {
        _stickyControls();
        _fixedFilters();

        $(window).on(
          'scroll',
          _.debounce(function () {
            _stickyControls();
            _fixedFilters();
          }, 10)
        );
        $filtersContainer.on('filters.visible', function () {
          _fixedFilters();
        });
        if (!bypassOrder) {
          $(document).on('inv_status_sort:update', '.js-product', function (e) {
            // The page load L2 event get's triggered before Drupal so all items already have the
            // invSort data set, but we need to listen for the update in Drupal as well to re sort the grid
            // if the item get's updated a second time
            if (!$mixItUpEl.hasClass('js-product-grid--sorted')) {
              $mixItUpEl.mixItUp('sort', defaultSort);
            }
          });
        }
        $carousels.on('deferred-carousel-load', function () {
          var $this = $(this);

          $this.once('deferred-carousel-load', function () {
            destroyCarousel($this);
          });
        });
      }

      function destroyCarousel($carousel) {
        $carousel.unslick();
        $carousel.removeClass('slick-initialized slick-slider');
        // we need to remove the inline css set by slick, all slides have width: 0
        // if the slider is initialized while hidden
        $carousel.find('.js-product-grid__item--slide').css({ width: '' });
        initCarousel($carousel);
        $(document).trigger('grid.carousel.visible', { gridCarousel: $carousel });
        $(document).trigger('grid.carousel.lazyloaded', {
          gridCarousel: $carousel,
          selector: '.js-product-grid-item'
        });
      }

      function initControlls() {
        if (
          !$mppContainer.hasClass('js-mpp-container--has-filters') &&
          !$mppContainer.hasClass('js-mpp-container--has-sort')
        ) {
          return;
        }
        // we need the controls container visible to get it's height
        $controlsContainer.removeClass('hidden');

        var $filterActiveOptionsList = $('.js-mpp-filter-active-options', $controlsContainer);
        if (
          $filterActiveOptionsList.length > 0 &&
          $filterActiveOptionsList[0].scrollWidth > $filterActiveOptionsList[0].clientWidth
        ) {
          $filterActiveOptionsContainer.addClass('scrollable');
        } else {
          $filterActiveOptionsContainer.removeClass('scrollable');
        }

        _stickyControls();
        _fixedFilters();
      }

      function _fixedFilters() {
        if (isMobile) {
          var maxHeight = $(window).height();

          $filtersContainer.addClass('sticky').css({ 'max-height': maxHeight + 'px' });
          var siteHeaderHeight = $('.js-site-header', context).outerHeight(true);
          var footerHeight = $('.js-mpp-filter-set__footer', $filtersContainer).outerHeight(true);
          var headerHeight = $('.js-mpp-filter-set__header', $filtersContainer).outerHeight(true);
          var $filtersBody = $('.js-mpp-filter-set__body', $filtersContainer);
          var maxBodyHeight = maxHeight - headerHeight - footerHeight;

          maxBodyHeight = $('body').hasClass('js-sticky-header')
            ? maxBodyHeight
            : maxBodyHeight - siteHeaderHeight;
          $filtersBody.css({
            height: maxBodyHeight + 'px',
            'max-height': '50vh'
          });
        } else {
          $filtersContainer.removeClass('sticky');
        }
      }
      function _stickyControls() {
        var headerHeight = 0;
        var processStickyHeader = true;
        var startPos = $mppContainer.offset().top;
        var currentPos = $(window).scrollTop();
        var $sortMenu = $('.js-mpp-sort-menu-selectBox-dropdown-menu', context);

        if ($controlsContainer.data('isSticky')) {
          if (processStickyHeader && $('body').hasClass('js-sticky-header')) {
            // if the header is sticky we need to use it in the startPos check
            headerHeight = $('.js-site-header-sticky').outerHeight(true);
            startPos = startPos - headerHeight;
            processStickyHeader = false;
          }
          currentPos = $(window).scrollTop();
          if (currentPos >= startPos) {
            $controlsContainer.addClass('sticky');
            $sortMenu.addClass('sort-sticky');
          } else {
            $controlsContainer.removeClass('sticky');
            $sortMenu.removeClass('sort-sticky');
          }
        } else {
          $controlsContainer.removeClass('sticky');
          $sortMenu.removeClass('sort-sticky');
        }
      }
      function initCarousel($carousel) {
        var arrowsDiv = $carousel.parent().find('.carousel-controls');
        var dotsDiv = $carousel.parent().find('.carousel-dots');
        var slidesShowCountSmall = $carousel.data('slides-show-small') || 1;
        var slidesShowCountMedium = $carousel.data('slides-show-medium') || 2;
        var slidesShowCountLarge = $carousel.data('slides-show-large') || 3;
        var availableSlides = parseInt($carousel.find('.js-product-grid__item--slide').length);
        var disableInfiniteCarousel = $carousel.hasClass('js-disable-infinite-carousel');

        // If the slider tries to show more slides then available it messes up the view of the slides
        if (availableSlides <= slidesShowCountSmall) {
          slidesShowCountSmall = 1;
        }
        if (availableSlides <= slidesShowCountMedium) {
          slidesShowCountMedium = slidesShowCountSmall;
        }
        if (availableSlides < slidesShowCountLarge) {
          slidesShowCountLarge = slidesShowCountMedium;
        }
        var settings = {
          rtl: site.direction.isRTL,
          appendArrows: arrowsDiv,
          arrows: true,
          appendDots: dotsDiv,
          dots: true,
          infinite: disableInfiniteCarousel ? false : true,
          slidesToScroll: 1, // if changed the slick-active won't be added, and QS ^ (position arrow) will get messed up
          onInit: function (_slick) {
            _slick.$slider.trigger('init', _slick);
          },
          onBeforeChange: function (_slick, _currentSlide, _nextSlide) {
            // _currentSlide = The slideIndex the carousel shows first ( this is not really the slide that is active)
            // _nextSlide = The slideIndex the carousel will land on after animation is done
            _slick.$slider.trigger('beforeChange', _slick, _currentSlide, _nextSlide);
          },
          onAfterChange: function (_slick, _curentSlide) {
            _slick.$slider.trigger('afterChange', _slick, _curentSlide);
          },
          onSetPosition: function (_slick) {
            _slick.$slider.trigger('afterSetPosition', _slick);
          },
          slidesToShow: slidesShowCountLarge,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: slidesShowCountMedium
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: slidesShowCountSmall
              }
            }
          ]
        };

        $carousel.on('afterSetPosition', function (e, _slick) {
          if (_slick.slideWidth) {
            $carousel
              .once('grid-carousel-slick-visible')
              .trigger('grid.carousel.visible', { gridCarousel: $carousel });
            _slick.$slides.removeClass('hidden');
          }
        });
        // Init this carousel with our settings
        $carousel.slick(settings);
      }

      function initCarouselsGrid() {
        // Loop through and init the carousels.
        // Carousels might contain variations, so dynamically change the settings before constructing slick
        // @setup - new brand to adjust responsive/dots settings per designs
        $carousels.each(function () {
          initCarousel($(this));
        });
      }
    }
  };
})(jQuery);
